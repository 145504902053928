
  export default {
    props: {
      job: {
        type: Object,
        required: true,
      },
      showOrganization: {
        type: Boolean,
        required: true,
        default: true,
      },
      badgeLocationType: {
        type: String,
        required: false,
        default: 'job-listing',
      },
      isPowerJob: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        beforeDays: 'Vor 10 Tagen',
        topRemuneration: 'Top-Vergütung',
        topJobProvider: 'Top-Jobanbieter',
      };
    },
  };
